<template>
  <div id="certifications-section">
        <div class="certifications--margin certifications__wrapper certifications__wrapper--color d-md-none">
            <carousel :perPage="1"  :paginationEnabled="false" :autoplay="true" :autoplayTimeout="3000">
                <slide class="d-flex justify-content-around">
                    <img class="mx-2" :src="require('@/assets/cert1.png')" width="100" height="76">
                    <img class="mx-2" :src="require('@/assets/cert2.png')" width="108" height="76">
                </slide>
                <slide class="d-flex justify-content-around">
                    <img class="mx-2" :src="require('@/assets/cert3.png')" width="66" height="76">
                    <img class="mx-2" :src="require('@/assets/cert4.png')" width="59" height="76">
                    <img class="mx-2" :src="require('@/assets/cert5.png')" width="60" height="76">
                </slide>
            </carousel>
        </div>
        <div class="certifications__wrapper d-none
            certifications--margin
            certifications__wrapper--color
            d-md-flex flex-row flex-wrap
            justify-content-around
            align-items-center">
            <certification class="d-none d-sm-block" image="cert1.png" imgWidth="100" imgHeight="76"/>
            <certification class="d-none d-sm-block" image="cert2.png" imgWidth="108" imgHeight="76"/>
            <certification class="d-none d-sm-block" image="cert3.png" imgWidth="66" imgHeight="76"/>
            <certification class="d-none d-sm-block" image="cert4.png" imgWidth="59" imgHeight="76"/>
            <certification class="d-none d-sm-block" image="cert5.png" imgWidth="60" imgHeight="76"/>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
    name: 'CertificationsSection', 
    components: {
        'certification': () => import('./Certification'),
        Carousel,
        Slide
    }
}
</script>

<style scoped>
.carousel-size{
    max-width: 320px!important;
}
.certifications__wrapper{
    width: 100%;
    height: 85px;
    transition: height 0.3s;
}
.certifications__wrapper--color{
    background-color: var(--ksr-smoke);
}
.certifications--margin{
    margin-top: 140px;
}
@media (min-width: 992px) {
    .certifications__wrapper{
        height: 125px;
        transition: height 0.3s;
    }
}
</style>