<template>
    <div id="contact-form">
        <div class="contact-form--wrapper contact-form--shadow contact-form--color contact-form--margin">
                <div v-if="sentForm == false" class="d-flex flex-column align-items-center">
                    <div class="contact-form__text">
                        <div class="d-flex flex-column align-items-center">
                            <div class="contact-form__title--margin d-flex justify-content-center align-items-center">
                                <h2 class="contact-form__title--style contact-form__text--color mb-0">
                                    {{ title }}
                                </h2>
                            </div>
                            <p class="contact-form__subtitle--style contact-form__text--color d-none d-sm-block mb-0">
                                {{ subtitle }}
                            </p>
                        </div>
                    </div>
                    <adwords @getParameters="updateParameters" /> 
                    <form 
                        @submit="checkForm"
                        id="form-section" 
                        novalidate="true" 
                        class="contact-form__inputs mt-2 mt-sm-3"
                    >
                        <contact-form-input 
                            inputLabel="Nombre"
                            inputErrorLabel="Introduzca un nombre válido"
                            inputType="text"
                            @inputChanged="updateInputOneValue"
                            @isValid="updateInputOneValid"
                        />
                        <contact-form-input
                            inputLabel="Correo electrónico"
                            inputErrorLabel="Introduzca un correo electrónico válido"
                            inputType="mail"
                            @inputChanged="updateInputTwoValue"
                            @isValid="updateInputTwoValid"
                    />
                        <contact-form-input
                            inputLabel="Teléfono"
                            inputErrorLabel="Introduzca un teléfono válido"
                            inputType="tel"
                            @inputChanged="updateInputThreeValue"
                            @isValid="updateInputThreeValid"
                        />
                        <contact-form-input
                            inputLabel="Empresa"
                            inputErrorLabel="Introduzca una empresa válida"
                            inputType="text"
                            @inputChanged="updateInputFourValue"
                            @isValid="updateInputFourValid"
                        />
                        <div class="d-flex justify-content-center w-100 mt-2">
                            <button v-if="isLoading == false" :class="{'contact-form__button--blocked': isButtonDisabled}" class="contact-form__button contact-form__button--color" type="submit" value="submit">{{ button }}</button>
                            <div v-if="isLoading" class="loader"></div>
                        </div>
                    </form>
                </div>
            <div v-if="sentForm" class="d-fle flex-coumn align-items-center">
                <div class="contact-form__title-sent">
                    <p class="contact-form__title-sent--style">
                        {{ sentTitle }}
                    </p>
                </div>
                <div class="contact-form__text-sent">
                    <p class="contact-form__text-sent--style contact-form__text--color pt-3 px-5">
                        {{ sentText }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ContactFormInput from './ContactFormInput.vue'
import Adwords from './Adwords'

export default {
    name: 'ContactForm',
    props: {
        title: String, 
        subtitle: String, 
        button: String, 
        sentTitle: String, 
        sentText: String, 
    },
    components: {
        'contact-form-input': ContactFormInput, 
        'adwords': Adwords
    },
    data() {
        return {
            inputOneValue: "", 
            isInputOneValid: null, 
            inputTwoValue: "",
            isInputTwoValid: null, 
            inputThreeValue: "", 
            isInputThreeValid: null, 
            inputFourValue: "",
            isInputFourValid: null,
            isButtonDisabled: null, 
            parameters: {},
            sentForm: false, 
            isLoading: false
        }
    },
    methods: {
        checkForm: function(e) {
            if( this.inputOneValue == "" || 
                this.inputTwoValue == "" || 
                this.inputThreeValue == "" || 
                this.inputFourValue == "") {
                    e.preventDefault();
            }
            else{
                e.preventDefault();
                var self = this; 
                this.isLoading = true; 
                var xhr = new XMLHttpRequest();
                xhr.open('POST','/utils/sendMail.php' , true);
                xhr.setRequestHeader('Content-type', 'application/json');
                xhr.onload = function () {
                    if(this.responseText == "OK") {
                        self.sentForm = true; 
                        self.$gtag.event('Sent', { 
                           'event_category': 'Lead', 
                           'event_label': 'Lead LP Sopladores', 
                        });
                        this.$gtag.event('conversion', { 
                            'send_to': 'AW-1015462655/izL4CJCS-KUBEP_1muQD', 
                            'event_callback': () => {}, 
                        });
                    }
                }; 
                xhr.send(JSON.stringify({
                    'name': this.inputOneValue, 
                    'email': this.inputTwoValue,
                    'phone': this.inputThreeValue,
                    'company': this.inputFourValue,
                    'last_click': this.lastClick, 
                    'utm_source': this.parameters.utm_source, 
                    'utm_medium': this.parameters.utm_medium, 
                    'utm_campaign': this.parameters.utm_campaign, 
                    'utm_term': this.parameters.utm_term, 
                    'utm_content': this.parameters.utm_content, 
                    'gclid': this.parameters.gclid, 
                    'organic_Search': this.parameters.isOrganicSearch, 
                    'referrer': this.parameters.referrer
                }));  
            }
        },
        updateInputOneValue: function(value) {
            this.inputOneValue = value;
        }, 
        updateInputOneValid: function(value) {
            this.isInputOneValid = value;
            this.disableButton(); 
        }, 
        updateInputTwoValue: function(value) {
            this.inputTwoValue = value;
        },
        updateInputTwoValid: function(value) {
            this.isInputTwoValid = value;
            this.disableButton(); 
        }, 
        updateInputThreeValue: function(value) {
            this.inputThreeValue = value;
        },
        updateInputThreeValid: function(value) {
            this.isInputThreeValid = value;
            this.disableButton(); 
            
        }, 
        updateInputFourValue: function(value) {
            this.inputFourValue = value;
        }, 
        updateInputFourValid: function(value) {
            this.isInputFourValid = value;
            this.disableButton(); 
        }, 
        updateParameters: function(values) {
            this.parameters = values; 
        },
        disableButton: function() {
            if( this.isInputOneValid == false || 
                this.isInputTwoValid == false || 
                this.isInputThreeValid == false || 
                this.isInputFourValid == false) {
                this.isButtonDisabled = true;
            }
            else {
                this.isButtonDisabled = false;
            }
        }, 
    }, 
    computed: {
        lastClick: function() {
            return this.$store.getters.getLastClick; 
        }
    }
}
</script>

<style scoped>
    /* Texts */
    .contact-form__title--style{
        font-size: 20px;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        transition: font-size 0.3s;
    }
    .contact-form__subtitle--style{
        font-size: 13px;
        font-weight: 300;
        text-align: center;
    }
    .contact-form__title-sent--style{
        font-size: 30px;
        font-weight: 500;
        text-align: center;
        transition: font-size 0.3s;
    }
    .contact-form__text-sent--style{
        font-size: 18px;
        font-weight: 300;
        text-align: center;
        transition: font-size 0.3s;
    }
    .contact-form__text--color{
        color: var(--ksr-iron);
    }
    /* Wrappers */ 
    .contact-form--wrapper{
        width: 290px;
        height: 484px;
        transition: width 0.3s, height 0.3s;
    }
    .contact-form__text{
        width: 150px;
        height: 55px;
        transition: width 0.3s, height 0.3s;
    }
    .contact-form__button{
        width: 135px;
        height: 40px;
        transition: width 0.3s, height 0.3s;
    }
    /* Margins */
    .contact-form__title--margin{
        margin-top: 20px;
    }
    .contact-form__title-sent--style{
        padding-top: 125px;
    }
    /* Styles */
    .contact-form--color{
        background-color: var(--ksr-white);
    }
    .contact-form--shadow{
        box-shadow: 0px 1.12694px 10.0172px rgba(0, 0, 0, 0.0525824), 0px 9px 80px rgba(0, 0, 0, 0.07);
    }
    .contact-form__button--color{
        background-color: var(--ksr-yellow);
        color: var(--ksr-black);
        transition: background-color 0.3s, color 0.3s!important;
    }
    .contact-form__button--color.contact-form__button--blocked{
        background-color: var(--ksr-gray);
        color: var(--ksr-white);
        cursor: not-allowed;
        pointer-events: none;
        transition: background-color 0.3s, color 0.3s;
    }
    .contact-form__button--color:hover{
        background-color: var(--ksr-gray);
        color: var(--ksr-white);
        transition: background-color 0.3s, color 0.3s;
    }
    .loader{
        border: 10px solid var(--ksr-lightGray);
        border-radius: 50%;
        border-top: 10px solid var(--ksr-yellow);
        border-bottom: 10px solid var(--ksr-yellow);
        width: 50px;
        height: 50px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
    button{
        border: none!important;
        outline: none!important;
    }
    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    @media (min-width: 576px) {
        /* Texts */
        .contact-form__title--style{
            font-size: 22px;
            transition: font-size 0.3s;
        }
        .contact-form__title-sent--style{
            font-size: 35px;
            transition: font-size 0.3s;
        }
        .contact-form__text-sent--style{
            font-size: 22px;
            transition: font-size 0.3s;
        }
        /* Wrappers */ 
        .contact-form--wrapper{
            width: 382px;
            height: 495px;
            transition: width 0.3s, height 0.3s;
        }
        .contact-form__text{
            width: 290px;
            height: 75px;
            transition: width 0.3s, height 0.3s;
        }
        .contact-form__button{
            width: 169px;
            height: 43px;
            transition: width 0.3s, height 0.3s;
        }  
    }
</style>
