<template>
    <div id="adwords"></div>
</template>

<script>
export default {
    name: 'Adwords', 
    data() {
        return {
            utm_source: this.getParameterByName('utm_source'), 
            utm_medium: this.getParameterByName('utm_medium'), 
            utm_campaign: this.getParameterByName('utm_campaign'),
            utm_term: this.getParameterByName('utm_term'), 
            utm_content: this.getParameterByName('utm_content'), 
            gclid: this.getParameterByName('gclid'), 
            referrer: document.referrer,
            isOrganicSearch: this.checkOrganic()  
        }
    }, 
    methods: {
        getParameterByName: function(name) {
            name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
            return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
        },
        checkOrganic: function() {
            if( this.utm_source == '' && 
                this.utm_mediumm == '' &&
                this.utm_campaign == '' &&
                this.utm_term == '' &&
                this.utm_content == '' &&
                this.gclid == '' &&
                this.referrer.length > 0) {
                    return "True"; 
            }
            else {
                return "False"; 
            }
        }
    },
    mounted() {
        this.$emit('getParameters', this.$data);
    },
}
</script>