<template>
    <div class="contact-form-input">
        <label class="input__label--style input__label-color mr-2 mb-0" for="inputOne">
            {{ inputLabel }}
        </label>
        <font-awesome-icon v-if="isInputValid" class="input__label-icon--color" icon="check-circle" size="sm"/>
        <font-awesome-icon v-if="isInputValid == false" class="input__label-icon--color-wrong" icon="exclamation-circle" size="sm"/>
        <br>
        <input required v-model="inputValue" @keyup="checkInputValue" :class="checkIfFalse(isInputValid)" class="input__text--style input--size input--color input--border" type="text" :name="inputLabel" autocomplete="off" maxlength="40">
        <div class="input__error d-flex justify-content-center mb-2 mb-sm-0">
            <small v-if="isInputValid == false" class="input__error--style input__error--color mb-0">
                {{ inputErrorLabel }}
            </small>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactFormInput', 
    props: {
        inputLabel: String,
        inputErrorLabel: String, 
        inputType: String
    },
    data () {
        return {
            inputValue: "", 
            isInputValid: null 
        }
    },
    methods: {
        checkInputValue: function(e) {
            if(e.key != "Tab") {
                if(this.inputType == "text") {
                    if(this.inputValue.trim() != "") {
                        this.isInputValid = true; 
                        this.$emit('inputChanged', this.inputValue);
                        this.$emit('isValid', true);
                    }
                    else {
                        this.isInputValid = false; 
                        this.$emit('isValid', false);
                    }
                }
                else if(this.inputType == "mail") {
                    if(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.inputValue)) {
                        this.isInputValid = true;
                        this.$emit('inputChanged', this.inputValue);
                        this.$emit('isValid', true);
                    }   
                    else {
                        this.isInputValid = false;
                        this.$emit('isValid', false);
                    }  
                }
                else if(this.inputType == "tel") {
                    if(/^([+]{1}[0-9]{1,3})?[\s-]?([(][0-9]{2,4}[)]|[0-9]{2,4})[\s-]?([0-9]{3,4})[\s-]?([0-9]{4})$/.test(this.inputValue.trim())) {
                        this.isInputValid = true;
                        this.$emit('inputChanged', this.inputValue);
                        this.$emit('isValid', true);
                    }
                    else {
                        this.isInputValid = false;
                        this.$emit('isValid', false);
                    } 
                }
            } 
        },
        checkIfFalse: function(inputValid) {
            if(inputValid == false) {
                return "input--border-wrong";
            }
        }
    }
}
</script>

<style scoped>
    /* Text */
    .input__label--style{
        font-size: 16px;
        font-weight: 300;
    }
    .input__text--style{
        font-size: 16px;
        font-weight: 400;
    }
    .input__error--style{
        font-size: 13px;
        font-weight: 400;
    }
    .input__label-color{
        color: var(--ksr-iron);
    }
    .input__error--color{
        color: var(--ksr-red);
    }
    /* Wrappers */
    .input__error{
        width: 100%;
        height: 18px;
    }
    .input--size{
        width: 230px;
        height: 40px;
        padding: 10px;
        transition: all 0.3s;
    } 
    /* Styles */
    .input--color{
        background-color: var(--ksr-lightGray);
    }
    .input--border:focus{
        border: 1px solid var(--ksr-yellow)!important;
        transition: border 0.3s;
    }
    .input--border.input--border-wrong{
        border: 1px solid var(--ksr-red)!important;
        transition: border 0.3s;
    }
    .input__label-icon--color{
        color: var(--ksr-green);
    }
    .input__label-icon--color-wrong{
        color: var(--ksr-red);
    }
    input, input:focus{
        outline: none!important;
        border: none!important;
    }
    @media (min-width: 576px) {
        .input--size{
            width: 300px;
            height: 38px;
            transition: all 0.3s;
        }
    }
</style>
