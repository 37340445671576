import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
      lastClick: null, 
    }, 
    mutations: {
      clickingCTA(state, sectionID) {
        state.lastClick = sectionID; 
      }
    }, 
    actions: {
        clickingCTA(context, sectionID) {
          context.commit('clickingCTA', sectionID); 
        }
    }, 
    getters: {
      getLastClick: state => {
        return state.lastClick; 
      }
    }
  }); 