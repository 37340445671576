<template>
    <div class="product-list">
        <b-container class="product-list--margin">
            <b-row>
                <b-col cols="12">
                    <div class="products-section__title">
                        <h3 class="products-section__title--style">
                            {{ title }}
                        </h3>
                        <h4 class="products-section__subtitle--style"> 
                            {{ subtitle }}
                        </h4>
                    </div>
                    <div class="products-section__list d-flex flex-wrap flew-row justify-content-around px-4">
                        <product-item
                            productID="1"
                            image="compresor-1.png"
                            title="Sopladores de tornillo de hasta 5650 cfm"
                            :items="[
                                { message: 'El perfil SIGMA de alta eficiencia con hasta 35% de ahorro de energía, ahora esta disponible en la línea de sopladores. Mínimo nivel de pulsaciones y ruido.' }, 
                                { message: 'Acceso frontal para servicio y todas las conexiones, ahorra espacio y permite la instalación LADO A LADO.' }, 
                                { message: 'Plug-and-play; solución completa lista para la puesta en marcha con sistema eléctrico y la técnica de control STC / SFC más moderna.' },
                                { message: 'Posible instalación a intemperie .' }
                            ]"
                        />
                        <product-item
                            productID="2"
                            image="compresor-2.png"
                            title="Sopladores de lóbulos rotativos Com-paK de hasta 2603 cfm"
                            :items="[
                                { message: 'Ahorro de espacio – en paquetes hasta 250 hp.' }, 
                                { message: 'Plug-and-play (conectar y operar).' }, 
                                { message: 'Acceso frontal para servicio y todas las conexiones, ahorra espacio y permite la instalación LADO A LADO.' }, 
                                { message: 'Posible instalación a intemperie.' },
                                { message: 'Opciones: sin cabina, con cabina, Plug & Play con cabina y sistema de control integrado STC/OFC.' },
                            ]"
                        />
                        <product-item
                            productID="3"
                            image="compresor-3.png"
                            title="Turbo Soplador con rodamientos magnéticos"
                            :items="[
                                { message: 'Potencia 200 y 400 hp Versiones LP, MP y HP.' }, 
                                { message: 'Motor de alta velocidad con cojinetes magnéticos, sin desgaste y a prueba de polvo.' }, 
                                { message: 'Para un número ilimitado de arranques del motor.' }, 
                                { message: 'Sistema de enfriamiento por agua-glicol AUTO-CONTENIDO.' }, 
                            ]"
                        />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

export default {
    name: 'ProductList',
    props: {
        title: String, 
        subtitle: String
    },
    components: {
       'product-item': () => import('./ProductItem') 
    }
}
</script>

<style scoped>
    .products-section__title--style{
        font-size: 22px;
        font-weight: 400;
        text-align: center;
    }
    .products-section__subtitle--style{
        font-size: 16px;
        font-weight: 300;
        text-align: center;
    }
    .product-list--margin{
        margin-top: 113px;
        transition: margin-top 0.3s;
    }
    @media (min-width: 992px) {
        .product-list--margin{
            margin-top: 150px;
            transition: margin-top 0.3s;
        }
    }
</style>