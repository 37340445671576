var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-list"},[_c('b-container',{staticClass:"product-list--margin"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"products-section__title"},[_c('h3',{staticClass:"products-section__title--style"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('h4',{staticClass:"products-section__subtitle--style"},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])]),_c('div',{staticClass:"products-section__list d-flex flex-wrap flew-row justify-content-around px-4"},[_c('product-item',{attrs:{"productID":"1","image":"compresor-1.png","title":"Sopladores de tornillo de hasta 5650 cfm","items":[
                            { message: 'El perfil SIGMA de alta eficiencia con hasta 35% de ahorro de energía, ahora esta disponible en la línea de sopladores. Mínimo nivel de pulsaciones y ruido.' }, 
                            { message: 'Acceso frontal para servicio y todas las conexiones, ahorra espacio y permite la instalación LADO A LADO.' }, 
                            { message: 'Plug-and-play; solución completa lista para la puesta en marcha con sistema eléctrico y la técnica de control STC / SFC más moderna.' },
                            { message: 'Posible instalación a intemperie .' }
                        ]}}),_c('product-item',{attrs:{"productID":"2","image":"compresor-2.png","title":"Sopladores de lóbulos rotativos Com-paK de hasta 2603 cfm","items":[
                            { message: 'Ahorro de espacio – en paquetes hasta 250 hp.' }, 
                            { message: 'Plug-and-play (conectar y operar).' }, 
                            { message: 'Acceso frontal para servicio y todas las conexiones, ahorra espacio y permite la instalación LADO A LADO.' }, 
                            { message: 'Posible instalación a intemperie.' },
                            { message: 'Opciones: sin cabina, con cabina, Plug & Play con cabina y sistema de control integrado STC/OFC.' } ]}}),_c('product-item',{attrs:{"productID":"3","image":"compresor-3.png","title":"Turbo Soplador con rodamientos magnéticos","items":[
                            { message: 'Potencia 200 y 400 hp Versiones LP, MP y HP.' }, 
                            { message: 'Motor de alta velocidad con cojinetes magnéticos, sin desgaste y a prueba de polvo.' }, 
                            { message: 'Para un número ilimitado de arranques del motor.' }, 
                            { message: 'Sistema de enfriamiento por agua-glicol AUTO-CONTENIDO.' } ]}})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }